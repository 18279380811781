"use strict";

require('core-js/fn/array/fill');

require('core-js/fn/array/find-index');

require('core-js/fn/array/find');

require('core-js/fn/array/from');

require('core-js/fn/math/sign');

require('core-js/fn/number/is-nan');

require('core-js/fn/string/includes');

require('core-js/fn/string/repeat');

require('core-js/modules/es6.array.iterator');

require('core-js/modules/es6.map');

require('core-js/modules/es6.promise');

require('core-js/modules/es6.set');

require('core-js/modules/es6.symbol');

require('core-js/modules/es6.weak-map');

require('core-js/modules/es7.array.includes');

require('core-js/modules/es7.object.entries');

require('core-js/modules/es7.object.values');

require('custom-event-polyfill');

require('whatwg-fetch');